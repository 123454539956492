export const perPageOptions = [1, 2, 3, 10, 15, 20, 50, 100]

export const pairDocumentTypes = {
  invoice: 'fe',
  nc: 'nc',
  nd: 'nd',
  ticket: 'te',
  cce: 'cce',
  cpce: 'cpce',
  rce: 'rce',
  quotation: 'qt',
  fce: 'fec',
  recurring: 'fr',
}
